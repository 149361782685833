import { Spinner } from "components/Elements";
import { MainLayout } from "components/Layout";
import { NotFound } from "features/misc";
import { lazyImport } from "utils/lazyImport";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const { ChangeLogRoutes } = lazyImport(
  () => import("features/changelog"),
  "ChangeLogRoutes"
);

const { ResourcesRoutes } = lazyImport(
  () => import("features/resources"),
  "ResourcesRoutes"
);
const { MessagesRoutes } = lazyImport(
  () => import("features/messages"),
  "MessagesRoutes"
);
const { PostsRoutes } = lazyImport(
  () => import("features/blog"),
  "PostsRoutes"
);
const { NoticesRoutes } = lazyImport(
  () => import("features/notices"),
  "NoticesRoutes"
);
const { PagesRoutes } = lazyImport(
  () => import("features/pages"),
  "PagesRoutes"
);

const { ApplicationsRoutes } = lazyImport(
  () => import("features/applications"),
  "ApplicationsRoutes"
);
const { FaqsRoutes } = lazyImport(() => import("features/faqs"), "FaqsRoutes");
const { MailingListRoutes } = lazyImport(
  () => import("features/mailingList"),
  "MailingListRoutes"
);
const { JobsRoutes } = lazyImport(
  () => import("features/careers"),
  "JobsRoutes"
);
const { AdsRoutes } = lazyImport(() => import("features/ads"), "AdsRoutes");
const { DirectorateRoutes } = lazyImport(
  () => import("features/directorates"),
  "DirectorateRoutes"
);
const { ManagersRoutes } = lazyImport(
  () => import("features/managers"),
  "ManagersRoutes"
);
const { MembersRoutes } = lazyImport(
  () => import("features/governingcouncil"),
  "MembersRoutes"
);
const { ServicesRoutes } = lazyImport(
  () => import("features/services"),
  "ServicesRoutes"
);
const { ComingSoon } = lazyImport(
  () => import("features/comingsoon"),
  "ComingSoon"
);
const { SettingsRoutes } = lazyImport(
  () => import("features/settings"),
  "SettingsRoutes"
);
// const { Settings } = lazyImport(() => import("features/settings"), "Settings");
const { Dashboard } = lazyImport(() => import("features/misc"), "Dashboard");
const { Profile } = lazyImport(() => import("features/users"), "Profile");
const { Users } = lazyImport(() => import("features/users"), "Users");
const { Multimedia } = lazyImport(
  () => import("features/multimedia"),
  "Multimedia"
);
const { GalleryRoutes } = lazyImport(
  () => import("features/gallery"),
  "GalleryRoutes"
);

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: "/app",
    element: <App />,
    children: [
      { path: "/app/changes/*", element: <ChangeLogRoutes /> },
      { path: "/app/careers/*", element: <JobsRoutes /> },
      { path: "/app/blog/*", element: <PostsRoutes /> },
      { path: "/app/services/*", element: <ServicesRoutes /> },
      { path: "/app/messages/*", element: <MessagesRoutes /> },
      { path: "/app/resources/*", element: <ResourcesRoutes /> },
      { path: "/app/pages/*", element: <PagesRoutes /> },
      { path: "/app/directorates/*", element: <DirectorateRoutes /> },
      { path: "/app/managers/*", element: <ManagersRoutes /> },
      { path: "/app/governing-council/*", element: <MembersRoutes /> },
      { path: "/app/mailing-list/*", element: <MailingListRoutes /> },
      { path: "/app/multimedia", element: <Multimedia /> },
      { path: "/app/notices/*", element: <NoticesRoutes /> },
      { path: "/app/applications/*", element: <ApplicationsRoutes /> },
      { path: "/app/faqs/*", element: <FaqsRoutes /> },
      { path: "/app/gallery/*", element: <GalleryRoutes /> },
      { path: "/app/ads/*", element: <AdsRoutes /> },
      { path: "/app/users", element: <Users /> },
      { path: "/app/settings/*", element: <SettingsRoutes /> },
      { path: "/app/profile", element: <Profile /> },
      { path: "/app/coming-soon", element: <ComingSoon /> },
      { path: "/app/", element: <Dashboard /> },
      { path: "/app/*", element: <NotFound /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
